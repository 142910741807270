import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { media } from 'theme/breakpoints';
import styled from 'styled-components';
import NewsletterTemplate from 'templates/newsletter';

export const Article = styled.div`
  & div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    & .title {
      margin: 0;
      font-size: 22px;
      ${media.mobile} {
        font-size: 18px;
      }
    }
    & p.subtitle {
      padding: 10px 0 15px;
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  & a.with-arrow {
    font-size: 16px;
    ${media.mobile} {
      font-size: 13px;
    }
  }
`;

export const TextContent = styled.div`
  display: block;
  margin-top: 24px;
  & p {
    margin-bottom: 12px;
  }
  & .head-2 {
    font-size: 20px;
  }
  & .desc-2 {
    color: #9b9b9b;
  }
`;

const Content = () => {
  const { userRoles, topuxevents, guide } = useStaticQuery(graphql`
    query {
      userRoles: file(relativePath: { eq: "blog/userrolesimage.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      topuxevents: file(relativePath: { eq: "blog/conferences-cover.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guide: file(relativePath: { eq: "blog/cover.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
      <tbody>
        <tr>
          <td>
            <table className="text" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <h1 style={{ margin: 0 }}>Hi,</h1>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0, borderBottom: '1px solid #e4e5e8', paddingBottom: 40 }}>
                      How was February? For us, it’s been quite busy!{' '}
                      <span role="img" aria-label="emoji">
                        💪{' '}
                      </span>{' '}
                      Here’s what’s new:
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 30px' }}>
                    <Article>
                      <div className="head">
                        <p className="title">
                          <strong>Product updates</strong>
                        </p>
                        <a
                          href="/updates/"
                          className="with-arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          See more
                        </a>
                      </div>
                      <p className="subtitle">
                        By popular demand, we’ve finally introduced user roles in the app{' '}
                        <span role="img" aria-label="emoji">
                          🎉
                        </span>
                      </p>
                      <Img
                        fluid={userRoles.childImageSharp.fluid}
                        alt="user-roles"
                        title="Product update - User roles"
                        style={{ borderRadius: 4, marginTop: 24 }}
                      />
                      <TextContent>
                        <p className="head-2">
                          <strong>User roles</strong>
                        </p>
                        <p className="desc-2">
                          Assign different levels of access to your teammates.
                        </p>
                        <a
                          href="/blog/user-roles/"
                          className="with-arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </a>
                      </TextContent>
                    </Article>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px 8px' }}>
                    <Article>
                      <div
                        className="head"
                        style={{ borderTop: '1px solid #e4e5e8', paddingTop: 30 }}
                      >
                        <p className="title">
                          <strong>Blog posts</strong>
                        </p>
                        <a
                          href="/blog/"
                          className="with-arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          See more
                        </a>
                      </div>
                      <p className="subtitle">
                        Learn about qualitative analytics and the hottest UX events in Central and
                        Eastern Europe{' '}
                        <span role="img" aria-label="emoji">
                          🔥
                        </span>
                      </p>
                      <Img
                        fluid={guide.childImageSharp.fluid}
                        alt="user-roles"
                        title="Product update - User roles"
                        style={{ borderRadius: 4, marginTop: 24 }}
                      />
                      <TextContent>
                        <p className="head-2">
                          <strong>Guide to Qualitative UX Research</strong>
                        </p>
                        <p className="desc-2">
                          You’ve got the “what”, now it’s time to get the “why”.
                        </p>
                        <a
                          href="/blog/guide-to-qualitative-ux-research/"
                          className="with-arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read more
                        </a>
                      </TextContent>
                    </Article>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 8px' }}>
                    <Article>
                      <Img
                        fluid={topuxevents.childImageSharp.fluid}
                        alt="user-roles"
                        title="Product update - User roles"
                        style={{ borderRadius: 4 }}
                      />
                      <TextContent>
                        <p className="head-2">
                          <strong>Top UX Events in CEE – 2020 Edition</strong>
                        </p>
                        <p className="desc-2">
                          Read about the best UX conferences in Central and Eastern Europe.
                        </p>
                        <a
                          href="/blog/top-ux-events-in-cee/"
                          className="with-arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read article
                        </a>
                      </TextContent>
                    </Article>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 8px' }}>
                    <p className="wishes" style={{ margin: 0 }}>
                      Have a nice day,
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '15px 40px 32px' }}>
                    <table className="kamil" cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <th>
                            <img
                              src="https://emails.livesession.io/kamil-rounded.png"
                              className="kamil-img"
                              style={{ maxWidth: '56px' }}
                              alt="Kamil"
                            />
                          </th>
                          <th style={{ paddingLeft: '16px' }}>
                            <p
                              className="info"
                              style={{
                                margin: 0,
                                textAlign: 'left',
                                fontWeight: 400,
                                lineHeight: '140%',
                              }}
                            >
                              <strong>Kamil</strong> <br />
                              CEO and Founder @ LiveSession
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Newsletter = NewsletterTemplate(Content);

export const frontmatter = {
  title: 'LiveSession Newsletter March 2020',
  description: "Read LiveSession's product updates and blog posts from March 2020.",
  date: '2020-03-01',
  link: '/newsletters/march-2020/',
};

export default () => <Newsletter {...frontmatter} />;
